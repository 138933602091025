* {
    scrollbar-width: thin;
    scrollbar-color: rgba(56, 115, 28) transparent;
    scrollbar-gutter: auto;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar:hover {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track-piece {
    background: transparent;
    width: fit-content;
}
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
    background: rgba(56, 115, 28);
    border-radius: 4px;
}
body,
body > :first-child > :first-child > :first-child > :first-child {
    /*noinspection CssInvalidPropertyValue*/
}
.theme {
    padding: 0;
    margin: 0;
    border: 0;
    position: relative;
    width: 100%;
    height: 100%;
}
.theme.light ::-webkit-scrollbar-thumb:vertical,
.theme.light ::-webkit-scrollbar-thumb:horizontal {
    background: rgba(56, 115, 28, .6);
}
.theme.light * {
    scrollbar-color: rgba(56, 115, 28, .6) transparent;
}
